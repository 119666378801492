import england from "../images/countries/mapa_anglia.png";
import poland from "../images/countries/mapa_polska.png";
import italy from "../images/countries/mapa_wlochy.png";
import germany from "../images/countries/mapa_niemcy.png";
import niderland from "../images/countries/mapa_holandia.png";
import portugal from "../images/countries/mapa_portugalia.png";
import russia from "../images/countries/mapa_rosja.png";
import spanish from "../images/countries/mapa_hiszpania.png";
import french from "../images/countries/mapa_francja.png";
import polyglotTitle from "../images/polish/product/mockup.webp";

import testimonial1 from "../images/testamonial/user.png";
import testimonial2 from "../images/testamonial/user.png";
import testimonial3 from "../images/testamonial/user.png";
import testimonial4 from "../images/testamonial/user.png";
import testimonial5 from "../images/testamonial/user.png";

import brandValuesPhoto1 from "../images/brandValues/wartosci_ambicja.webp";
import brandValuesPhoto2 from "../images/brandValues/wartosci_radosc.webp";
import brandValuesPhoto3 from "../images/brandValues/wartosci_ciekawosc.webp";
import brandValuesPhoto4 from "../images/brandValues/wartosci_efektywnosc.webp";

import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import mockup from "../images/mockup_pl.png";
import screen1 from "../images/polish/slider/2.png";
import screen3 from "../images/polish/slider/3.png";
import screen4 from "../images/polish/slider/4.png";
import screen5 from "../images/polish/slider/5.png";
import screen6 from "../images/polish/slider/6.png";
import screen7 from "../images/polish/slider/7.png";
import ScreenProduct from "../images/polish/product/laptop_phone_polyglot_PL.webp";

export const polish = {
  navbar: {
    data: [
      { title: "Zamów kurs", path: "/course" },
      { title: "Logowanie", path: "" },
      { title: "O Polyglot", path: "/product" },
      { title: "O nas", path: "/about" },
    ],
  },
  mainPage: {
    newSlovakProduct: {
      hero: {
        title: "Rozmawiaj pewnie w każdym języku!",
        subtitle: `Szybko zdobądź pewność siebie dzięki lekcjom skoncentrowanym na
        realistycznych konwersacjach.`,
        image: polyglotTitle,
      },
      label: {
        number: 9,
        language: "języków",
        stars: "4.7",
        rest: "14k ocen",
        topThree: "200+ tysięcy",
        bottomThree: "studentów",
      },
      video: {
        title: "Naucz się języka w prosty sposób",
        sub1: "Setki lekcji z przykładami języka używanego w prawdziwym życiu",
        sub2: "Praktyczne frazy przygotowane przez native speakerów",
        sub3: "Ćwiczenia efektywnie uczące czytania, słuchania i mówienia",
        title2: "Jak wygląda kurs?",
        image: ScreenProduct,
        images: [screen1, screen3, screen4, screen5, screen6, screen7],
        subs: [
          {
            title: "Lekcje z prawdziwego życia",
            text: `Każdy poziom składa się z dziesiątek lekcji. Jeśli nie wiesz, na jakim poziomie jesteś – zacznij od podstaw. Naucz się pozdrowień, dni tygodnia, jak zamówić jedzenie w restauracji. Każda lekcja zawiera przykłady z codziennego życia.`,
          },
          {
            title: "Ćwiczenia",
            text: `Niezależnie od tego, czy lubisz rozwiązywać krzyżówki każdego dnia, czy raczej unikasz takich zajęć, nie da się zaprzeczyć, że zagadki umysłowe doskonale trenują pamięć i pozwalają powtórzyć nauczony materiał.`,
          },
          {
            title: "Słowa, frazy i wyrażenia",
            text: `Każda lekcja jest uzupełniona o starannie wybrane obrazaki, dzięki którym proces uczenia się jest intuicyjny i kontekstowy. Najważniejsze tematy obejmują szeroki zakres słownictwa z codziennego życia i podróżowania, co pozwala na komunikację w tych obszarach bez tzw. blokady językowej.`,
          },
          {
            title: "Zapytaj eksperta",
            text: `Masz problem związany z językiem lub potrzebujesz pomocy w ćwiczeniu? Jesteśmy tutaj, aby Ci pomóc! Funkcja Zapytaj Eksperta daje możliwość skonsultowania się z ekspertem językowym, kiedykolwiek potrzebujesz pomocy. Po prostu wyślij swoje pytanie, a nasz specjalista językowy Ci odpowie. W ten sposób zawsze masz profesjonalne wsparcie na drodze nauki języków. Z funkcją Zapytaj eksperta żadne pytanie nie pozostaje bez odpowiedzi, a nauka staje się łatwiejsza niż kiedykolwiek wcześniej.`,
          },
        ],
      },
      languages: {
        title: "Dostępne języki",
        description:
          "Przygotuj się na kolejną podróż, uzupełnij swoje umiejętności językowe w CV lub zanurz się w nowej kulturze: odkryj obszerną kolekcję lekcji Polyglot i naucz się tego, co potrzebne, aby zacząć mówić, niezależnie od poziomu doświadczenia.",
        languages: [
          { image: england, lang: "Angielski" },
          { image: french, lang: "Francuski" },
          { image: spanish, lang: "Hiszpański" },
          { image: niderland, lang: "Holenderski" },
          { image: germany, lang: "Niemiecki" },
          { image: italy, lang: "Włoski" },
          { image: russia, lang: "Rosyjski" },
          { image: portugal, lang: "Portugalski" },
        ],
      },
    },
    navbar: [
      { title: "Zamów kurs", path: "/course" },
      { title: "Logowanie", path: "/login" },
      { title: "O Polyglot", path: "/product" },
      { title: "O nas", path: "/about" },
    ],
    hero: {
      title: "Dokąd zabierze Cię Twój nowy język?",
      right1: "Wakacje w tropikach?",
      right2: "Rodzina za granicą?",
      right3: "Wyższe stanowisko?",
      middle1: "Lepsze oceny?",
      middle2: "Praca w nowym miejscu?",
      left1: "Ambicje na “coś więcej”?",
    },
    poliglotWithNumbers: {
      title: "Polyglot w liczbach",
      count: [
        {
          number: 3,
          description: "poziomy",
        },
        {
          number: 24000,
          description: "użytkowników",
        },
        {
          number: 238,
          description: "lekcji",
        },
      ],
    },
    poliglotMap: {
      title: "Czym jest Polyglot?",
      box1: "Słowa, zwroty, wyrażenia",
      boxDescription1:
        "Każdej lekcji towarzyszą starannie dobrane zdjęcia, które sprawiają, że proces nauki jest intuicyjny i kontekstowy. Najczęściej używane tematy obejmują szeroki zakres słownictwa z życia codziennego i podróżniczego, dzięki czemu możliwe jest porozumiewanie się w tych obszarach bez tzw. blokady językowej.",
      box2: "Mnogość lekcji",
      boxDescription2:
        "W miarę zdobywania doświadczenia liczba lekcji, do których będziesz mieć dostęp, będzie większa. Jeśli zdecydujesz się na naukę od podstaw, zacznij od typów lekcji, które obejmują tematy od prostych do złożonych. Każda lekcja nie jest tylko nauką pojedynczych słówek, ale zawiera również zróżnicowane zadania. ",
      box3: "30 minut dziennie",
      boxDescription3:
        "Nie czuj presji. Lekcje możesz rozpocząć i skończyć w dowolnym momencie. Nic się nie stanie, jeśli codzienne obowiązki pochłoną cały Twój czas. To właśnie wyróżnia nasz kurs – elastyczność czasowa. Sugerujemy jednak, aby poświęcić na naukę 30 minut dziennie – przynajmniej od czasu do czasu. Dzięki temu proces zapamiętywania będzie przebiegał jeszcze sprawniej, a Ty jeszcze szybciej zaczniesz mówić w obcym języku, tak jak rodowity mieszkaniec zagranicy.",
      box4: "Certyfikat",
      boxDescription4:
        "Certyfikat ukończenia kursu to świetne wyróżnienie. Każdy przecież chciałby uzyskać potwierdzenie uzyskania nowych umiejętności. Po ukończeniu wszystkich trzech poziomów kursu i zdaniu testu na zakończenie – otrzymasz bezpłatny certyfikat. Możesz go wydrukować i być dumnym z siebie uczestnikiem. Gratulacje – udało się. Pokaż go rodzinie i znajomym, pochwal się szefowi w pracy i mów biegle w obcym języku!",
      box5: "3 poziomy nauki",
      boxDescription5:
        "Niezależnie od tego, czy nie znasz kompletnie języka obcego, czy potrafisz się przedstawić, zamówić kawę, czy porozmawiać z zięciem zza granicy – Polyglot jest dla każdego. Dlatego kurs został podzielony na poziomy podstawowy, średniozaawansowany i zaawansowany. Na każdym z poziomów, algorytmy nauki nieustannie dobierają odpowiednie słownictwo, tak by nauka nie zaskoczyła nikogo zbyt trudnym poziomem. Po ukończeniu poziomu podstawowego, możesz przejść do poziomu średniego lub zaawansowanego. To 3 kursy w cenie jednego!",
    },
    avaiableLanguages: {
      title: "Dostępne języki",
      text: "Czy zawsze chciałeś uczyć się języka obcego? Wybór odpowiedniego języka to ważna decyzja, która może zmienić Twoje życie na lepsze. ",
      text1:
        "Posługiwanie się nowym językiem może otworzyć drzwi do nowych możliwości w miarę rozwoju.",
      text2:
        "Osiągnięcie większej biegłości w jakimkolwiek języku wymaga czasu, poświęcenia, entuzjazmu, motywacji i wytrwałości. Platforma językowa Polyglot została stworzona w taki sposób, by ułatwić Ci naukę dowolnego języka.",
      languages: [
        { image: england, lang: "Angielski" },
        { image: french, lang: "Francuski" },
        { image: spanish, lang: "Hiszpański" },
        { image: niderland, lang: "Niderlandzki" },
        { image: germany, lang: "Niemiecki" },
        { image: italy, lang: "Włoski" },
        { image: russia, lang: "Rosyjski" },
        { image: portugal, lang: "Portugalski" },
      ],
    },
    testimonial: {
      title: "Użytkownicy piszą",
      elements: [
        {
          id: 1,
          photo: testimonial1,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Uczymy się całą rodziną!",
          text: "Wspaniała aplikacja! Bogata i logicznie pogrupowana baza słówek i masa różnorodnych rodzajów ćwiczeń sprawia, że idealnie można dzięki niej szlifować język. Sama wykorzystałam Polyglot do rosyjskiego i jestem zachwycona!",
          bottomInfo: "Grażyna Fiołek",
        },
        {
          id: 2,
          photo: testimonial2,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Z Eureką czas płynie szybciej!",
          text: "Uważam, że aplikacja jest świetna do nauki słownictwa i prostych zasad gramatycznych. Uczę się niemieckiego z Polyglot. Wiadomo, że należy również szukać innych źródeł wiedzy, ale podstawy czytania, słuchania i mówienia mam już opanowane.",
          bottomInfo: "Mariusz Kryżan",
        },
        {
          id: 3,
          photo: testimonial3,
          date: "24 stycznia 2022",
          stars: 4,
          title: "Nauka dla zapracowanych!",
          text: "Szczerze mówiąc, nie sądziłem, że to będzie aż tak dobre! Każdy z łatwością nauczy się języków obcych. ",
          bottomInfo: "Gracjan Kościuszko",
        },
        {
          id: 4,
          photo: testimonial4,
          date: "24 stycznia 2022",
          stars: 4.5,
          title: "Teraz mieszkam za granicą!",
          text: "Moim celem jest nauka hiszpańskiego. Ponieważ nie należę do osób cierpliwych, nie chcę, aby zajęło mi to 6 lat (jak lekcje w szkole). Już widzę, jak dobra jest to platforma! W ciągu 10 minut uczę się kilku słów!",
          bottomInfo: "Elżbieta Maj",
        },
        {
          id: 5,
          photo: testimonial5,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Nigdy nie jest za późno",
          text: "Jestem bardzo zadowolona z nauki na Polyglot. W końcu robię coś dla siebie!",
          bottomInfo: "Iwona Czynszak",
        },
        {
          id: 6,
          photo: testimonial5,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Nigdy nie jest za późno",
          text: "Uwielbiam zadania, to naprawdę najlepszy sposób, by nauczyć się języka. Osobiście bardzo lubię tę platformę, bardzo mi pomogła w nauce słów i rozróżnianiu dźwięków, uwielbiam ją! Bardzo dobra robota i nie mogę się doczekać, co się stanie z tą aplikacją w przyszłości…",
          bottomInfo: "Marian ZIeliński",
        },
      ],
    },
    course: {
      title: "Jak wygląda kurs?",
      mockupPolyglot: mockup,
      right:
        "Na platformie Polyglot nie znajdziesz nudnych, suchych reguł gramatycznych, które trzeba zapamiętać w ciągu jednego dnia. Nauka polega na poznawaniu zdań i wyrażeń, takich jakie są używane w życiu codziennym, a następnie na utrwaleniu ich w pamięci dzięki prostym ćwiczeniom i powtórzeniom. Kurs został tak zaprojektowany, aby nauka stała się przyjemnością. Podczas kursu masz możliwość skorzystania z:",
      right2Title: "Lekcje z życia wzięte",
      right2Bottom:
        "Każdy poziom nauki składa się z kilkudziesięciu lekcji. Jeśli nie wiesz na jakim poziomie jesteś – zacznij od podstaw. Naucz się przywitań, dni tygodnia, uczuć. Dowiedz się, jak zamówić posiłek w restauracji, oraz jak zamówić taksówkę. Każda lekcja, to przykłady z codziennego życia. Każda lekcja jest nieco inna, składa się z innych zadań i ćwiczeń, ale wszystkie układają się w jedną całość - tak by bez problemu można było opanować język. Lekcję możesz zacząć w dowolnym momencie. ",
      flexDown: [
        {
          title: "Podczas nauki natrafisz na takie elementy jak",
          text: `Nagrania uczące prawidłowej wymowy\n
         `,
          text1: "Frazy i zdania z życia wzięte\n",
          text2: "Obrazy ułatwiające zapamiętywanie słówek i wyrażeń\n",
          text3: "poprzez skojarzenia wizualne\n",
          text4: "...i wiele, wiele innych.",
        },
        {
          title: "Ćwiczenia",
          text: `Niezależnie od tego, czy na co dzień lubisz spędzać czas rozwiązując krzyżówki, czy wręcz przeciwnie, raczej unikasz takich zajęć, nie da się ukryć, że tego rodzaju mentalne łamigłówki doskonale ćwiczą pamięć i pozwalają powtórzyć przyswojony materiał.`,
        },
        {
          title: "Powtórki",
          text: `Bardziej zaawansowani kursanci mogą powtarzać słownictwo i sprawdzać swoją wiedzę z wszystkich obszarów tematycznych poprzez tryb ciągłej nauki. Zawiera on całe dostępne słownictwo, z wszystkich kategorii. Algorytm nauki nieustannie sprawdza poziom wiedzy i dopasowuje kolejne .`,
        },
      ],
    },
    learningWithPolyglot: {
      title: "Nauka\n z Polyglot",
      cards: [
        {
          icon: learning1,
          headline: `Krótkie, ale systematyczne lekcje`,
          text: `Podczas nauki języka najważniejsza jest regularność. Jednocześnie każdemu z nas trudno jest znaleźć czas, którego potrzebujemy. Mając to na uwadze, Polyglot została zaprojektowana do nauczania na krótkich, ale systematycznych lekcjach.
          `,
        },
        {
          icon: learning2,
          headline: `Lekcje bez wychodzenia z domu`,
          text: `Aby korzystać z aplikacji edukacyjnej Polyglot, nie potrzebujesz niczego poza telefonem, komputerem lub tabletem. Z Polyglot możesz uczyć się wszędzie: w kolejce, w autobusie, a przede wszystkim możesz uczyć się w domu bez konieczności nigdzie jechać. Uczysz się w wannie lub podczas spaceru z psem? Z programem Polyglot to możliwe!
          `,
          text2:
            "Nauka z Polyglot zaoszczędzi Ci dużo czasu spędzonego na dojazdach i możesz łatwo włączyć naukę do swojego harmonogramu!",
        },
        {
          icon: learning3,
          headline: `Najwyższa jakość`,
          text: `Podczas rozwoju Polyglot zespół ekspertów skupił się na jednym z najważniejszych celów: umożliwieniu naszej metody jak najszybszego opanowania języka obcego (niezależnie od wieku ucznia!).
          `,
        },
      ],
    },
    whyPolyglot: {
      title: "Dlaczego Polyglot?",
      textUp:
        "Jest to platforma o wieloaspektowym podejściu do nauki, która pomoże Ci opanować nowy język tak samo, jak w przypadku Twojego języka ojczystego – naturalnie.",
      textBottom:
        "Nauka języka obcego może zmienić Twoje życie. Nie tylko otwiera okno na świat pełen zawodowych i osobistych możliwości, ale także rozwija mysł i wzmacnia pewność siebie. Ponieważ technologie takie jak Internet zmniejszają dystans i stajemy się członkami globalnej społeczności, umiejętność uczenia się języka staje się istotną częścią naszej interakcji ze światem i sukcesu.",
      cards: [
        {
          headline: `Naucz się zwrotów i wyrażeń z życia codziennego,
           korzystając z naszej zaawansowanej technologii uczenia się.`,
          text: `Lekcje są stale dostosowywane do Twojego poziomu, dzięki czemu nigdy nie zabraknie Ci nudy ani motywacji.
          `,
        },
        {
          headline: `Zanurz się w obcym języku. Postaraj się w pełni 
          zrozumieć nowy język w oparciu o swoją znajomość kontekstu.`,
          text: `Dzięki tysiącom materiałów graficznych i dźwiękowych 
          zobaczysz i usłyszysz jak native speaker.
          `,
        },
        {
          headline: `Nabierz pewności siebie w mówieniu. Prawdziwym
           celem nauki języków jest również bycie zrozumiałym. `,
          text: `Z pomocą Polyglot wystarczy, że powtórzysz słownictwo, które usłyszałeś - nie bój się rozmawiać! Nasz system cię naprawi.

          `,
        },
        {
          headline: `Nie pozwól, by słowa umknęły Twojej pamięci. Ucz się poprzez 
          różnorodne ćwiczenia.`,
          text: `Starannie zaprojektowaliśmy elementy aplikacji tak, aby Twój umysł nigdy się nie nudził. Nieustannie przygotowujemy się i dodajemy nowe wyzwania do Polyglot, aby nauka była zabawą.

          `,
        },
      ],
    },
  },
  aboutUs: {
    navbar: [
      { title: "Zamow kurs", path: "/course" },
      { title: "Logowanie", path: "/login" },
      { title: "O Polyglot", path: "/product" },
      { title: "O nas", path: "/about" },
    ],
    mission: {
      title: "Misja",
      text1:
        "Naszym głównym celem jest, aby przy użyciu technologii umożliwić wszystkim dostęp do nauki takiej samej jakości, co prywatne korepetycje",
      title2: "Uniwersalny dostęp",
      textTitle2:
        "Ponad 1,2 miliarda ludzi uczy się języków obcych i większość z nich liczy, że dzięki temu zyska nowe szanse. Niestety nauka języka jest droga i dla wielu osób dostęp do niej jest zamknięty.",
      title3: "Pomost kulturowy",
      textTitle3:
        "Wierzymy, że język jest swego rodzaju pomostem łączącym różne kultury. Skłania do szukania porozumienia i podjęcia rozmów, zachęca do realizacji celów i dokonywania odkryć. Dla niektórych jest kluczem do sukcesu, dla innych przepustką do nowego świata.        ",

      textBottom:
        "Dlatego właśnie misją Platformy Edukacyjnej Polyglot jest zwiększanie efektywności i komfortu nauki języków. Wykorzystując potęgę technologii, możemy zjednoczyć świat za pomocą języka.  ",
    },
    approach: {
      title: "Podejście",
      text1:
        "Z naszych rozmów ze społecznością wynika, że wielu użytkownikom z całego świata podoba się Platforma Językowa Polyglot.          ",
      text2:
        "Naszą misją jest przełamywanie barier komunikacyjnych i integrowanie ludzi poprzez naukę języków.          ",
      text3:
        "Zrozumienie tego, co sobą reprezentujemy oraz jak się komunikujemy z naszą społecznością, jest niezbędne w celu dostosowania się do potrzeb naszej różnorodnej społeczności, jaką zbudowaliśmy.          ",
      paragraphData: [
        {
          id: 1,
          title: "Personalizacja",
          text: "Algorytmy uczenia maszynowego działają przez cały czas, aby dostarczać materiały do nauki na odpowiednim poziomie dla każdej osoby.",
        },
        {
          id: 2,
          title: "Zrównoważone podejście",
          text: `Polyglot Online umożliwia osobom uczącym się języków samodzielnie poznawać rozmaite wzorce, bez potrzeby skupiania się na zasadach językowych – tak samo, jak uczyliśmy się pierwszego języka jako dzieci. Takie podejście, zwane „utajonym uczeniem się”, jest idealne do rozwijania solidnej znajomości języka i jego zasad na poziomie podstawowym.`,
        },
        {
          id: 3,
          title: "Społeczność",
          text: "Dzięki językom ludzie się integrują. Poznawanie języków zachęca do szukania porozumienia. Społeczność platformy językowej Eureka jednoczy ludzi z całego świata.",
        },
        {
          id: 4,
          title: "Nieustanne ulepszenia",
          text: "Wciąż tworzymy nowe i lepsze kursy, powiększamy kolekcję naszych narzędzi do nauki języków, aktualizujemy materiały zgodnie z ewolucją danego języka, aby mieć pewność, że nieustannie oferujemy dostęp do najlepszej edukacji językowej na świecie. Podobnie jak język – nigdy nie przestajemy się rozwijać.",
        },
      ],
    },
    brandValues: {
      title: "Wartości marki",
      part2: {
        textInfo:
          "Wartości marki określają sposób naszej komunikacji, sposób pracy nad produktem oraz sposób, w jaki będziemy rozwijać platformę językową Polyglot w nadchodzących latach.",
        brandValuesData: [
          {
            photo: brandValuesPhoto1,
            title: "Ambicja",
            text: "Chcemy, aby nasi użytkownicy odnieśli sukces, niezależnie od stawianych sobie celów. Jesteśmy ambitni i nieustannie przesuwamy granice.",
          },
          {
            photo: brandValuesPhoto2,
            title: "Radość",
            text: "Uważamy, że nauka języków powinna być przyjemnością. Pomagamy ludziom czerpać fascynujące korzyści z umiejętności mówienia w innym języku.            ",
          },
          {
            photo: brandValuesPhoto3,
            title: "Ciekawość",
            text: "Odczuwamy nieustanne pragnienie wiedzy i rozwoju. Pasjonujemy się poznawaniem innych kultur. Pomagamy ludziom odkrywać świat dzięki językom.            ",
          },
          {
            photo: brandValuesPhoto4,
            title: "Efektywność",
            text: "Chcemy, aby ludzie uczyli się języków mądrze. Łączymy edukację z technologią.            ",
          },
        ],
      },
    },
  },
  formSender: {
    formControll: {
      titleUp: "Zamów",
      titleDown: "kurs",
      pageLink: "https://programy-osobniho-rozvoje.com/typ",
      title: "Zainteresował Cię kurs?",
      title1: "Chcesz uzyskać więcej informacji?",
      subtitle: "Zostaw nam swój numer –",
      subtitle2: "oddzwonimy do Ciebie",
      button1: "Imię i nazwisko",
      button2: "Numer telefonu",
      button3: "E-mail",
      buttonSub: "Zamów rozmowę > ",
      checkOne:
        "Wysyłając ten formularz zgadzam się na warunki regulaminu i oświadczam, że zapoznałem się z jego treścią",
      checkOneTwo: "a",
      checkTwo: "Wyrażam zgodę na kontakt w celach marketingowych",
      textOne: "Informujemy, że",
      textTwo:
        "Administratorem Państwa danych osobowych jest spółka EUROPEJSKA AGENCJA EDUKACYJNA Sp. z o.o. Sp. kom., z siedzibą w Warszawie, Aleja Solidarności 117 / 20, wpisana do Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy w Warszawie pod numerem KRS 0000973588, REGON: 522141653, NIP5252909818. Twoje dane osobowe będą przetwarzane w związku z obsługą Twojego zapytania w formularzu kontaktowym lub realizacją zawartej umowy. Kontaktując się z nami, użytkownik dobrowolnie wyraża zgodę na przetwarzanie swoich danych osobowych w powyższych celach. W związku z przetwarzaniem przez nas Twoich danych przysługuje Ci prawo dostępu do nich, ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do wniesienia sprzeciwu wobec przetwarzania oraz prawo do przenoszenia danych. Użytkownik ma również prawo do wycofania zgody w dowolnym momencie, ale wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem. Użytkownik ma również prawo do złożenia skargi do organu nadzorczego. Podanie danych jest oczywiście dobrowolne, ale może być konieczne do rozpatrzenia zapytania lub w związku z realizacją umowy. Dane osobowe będą przechowywane tak długo, jak będzie to konieczne do realizacji powyższych celów, ale nie przez okres przedawnienia ewentualnych roszczeń wynikających z przepisów prawa powszechnego. Prosimy o dokładne zapoznanie się z polityką prywatności",
      buttonMore: "więcej",
      buttonLess: "mniej",
      subOne: "warunki",
      subTwo: "i zasad ochrony danych.",
      buttonTXT: "Dziękujemy",
      buttonText:
        "Jeden z naszych konsultantów odezwie się do Ciebie w ciągu kilku najbliższych dni roboczych.",
      buttonText2: "Do usłyszenia!",
      privacyLink: "/Privacy_PL.pdf",
      rulesLink: "/Regulations_PL.pdf",
    },
  },
  footer: {
    privacy: "Polityka Prywatności",
    privacyLink: "/Privacy_PL.pdf",
    rules: "Regulamin",
    rulesLink: "/Regulations_PL.pdf",
  },
};
