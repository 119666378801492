import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import start from "../../../images/newProductPage/star.png";

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
`;

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  background-color: ${Colors.newYellow};
  width: 800px;
  border-radius: 20px;
  padding: 20px;

  @media (max-width: 600px) {
    width: 300px;
    flex-wrap: wrap;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    gap: 20px;
  }
`;

const LabelElement = styled.div`
  color: ${Colors.darkBlue};
  font-weight: bold;
  text-align: center;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    width: 250px;
  }
`;

const ItemUp = styled.div`
  font-weight: bold;
  font-size: 24px;
`;
const ItemDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

const Bold = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin: 0px 10px;
  margin-bottom: 2px;
`;

const ImageWrapper = styled.div`
  width: 100px;
  overflow: hidden;
  display: flex;

  /* @media (max-width: 600px) {
    display: none;
  } */
`;

const LabelInfo = ({ data }) => {
  return (
    <Wrapper>
      <Label>
        <LabelElement>
          <ItemUp>{data.number}</ItemUp>
          <ItemDown>{data.language}</ItemDown>
        </LabelElement>
        <LabelElement>
          <ItemDown>
            <Bold>{data.stars}</Bold>
            {data.rest}
          </ItemDown>
          <ImageWrapper>
            <img
              src={start}
              alt="Stars"
              style={{ width: "100%", height: "auto" }}
            ></img>
          </ImageWrapper>
        </LabelElement>
        <LabelElement>
          <ItemUp> {data.topThree}</ItemUp>
          <ItemDown>{data.bottomThree}</ItemDown>
        </LabelElement>
      </Label>
    </Wrapper>
  );
};

export default LabelInfo;
