import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import hero from "../../../images/zdj_2.jpg";
// import titleCloud from "../../../images/boxes/dymek_nagłówek-krótszy.png";
import cloud1 from "../../../images/kariera/kariera-1.png";
import cloud2 from "../../../images/kariera/kariera-2.png";
import cloud3 from "../../../images/kariera/kariera-3.png";
import cloud4 from "../../../images/kariera/kariera-4.png";
import {
  Background,
  Cloud,
  ColorChanger,
} from "../../reusable/exportedStyles/exportedStyles";
import Image from "./Image";

const Text = styled.h1`
  font-weight: lighter;
  font-size: 40px;
  max-width: 100px;
  height: auto;
  padding: 10px 10px 20px 20px;
  text-align: center;
  color: ${Colors.darkBlue};
`;

const GridBox = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
`;
const P = styled.p`
  color: white;
  font-size: 20px;
`;

const P2 = styled.p`
  max-width: 500px;
  text-align: center;
  color: ${Colors.darkBlue};
`;

const Flex1 = styled.div`
  display: flex;
  gap: 30px;
  margin-left: -120px;
  margin-bottom: 100px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Flex2 = styled.div`
  margin-left: -200px;
  gap: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;

  @media (max-width: 600px) {
    margin-left: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const ImageWrapper = styled.div`
  max-width: 500px;
  margin-bottom: 40px;
  margin-left: 80px;

  @media (max-width: 600px) {
    margin: 0px 20px 0px 0px;
  }
`;

const Career = () => {
  return (
    <>
      <Background img={hero}>
        <ColorChanger>
          <div style={{ width: "150px", alignSelf: "flex-end" }}>
            {/* <Cloud img={titleCloud} style={{ marginBottom: "-8px" }}>
              <Text>Career</Text>
            </Cloud> */}
          </div>
        </ColorChanger>
      </Background>
      <GridBox>
        <Text style={{ maxWidth: "550px", marginTop: "50px" }}>
          Work at Polyglot!
        </Text>
        <ImageWrapper>
          <Cloud img={cloud1}>
            <div style={{ marginLeft: "50px", padding: "15px" }}>
              <P>
                We're a multicultural, diverse group of people working to
                develop the best education in the world and make it universally
                available.
              </P>
            </div>
          </Cloud>
        </ImageWrapper>
        <Flex1>
          <Image photo={cloud2} style={{ marginTop: "-80px" }} />
          <Image photo={cloud3} />
        </Flex1>
        <Flex2>
          <div style={{ width: "60px", overflow: "hidden" }}>
            <img src={cloud4} style={{ width: "100%", height: "auto" }} />
          </div>
          <P2>We are not currently looking for employees</P2>
        </Flex2>
      </GridBox>
    </>
  );
};

export default Career;
