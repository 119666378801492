import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";

import photo from "../../../images/zdj_3.jpg";
import mockup from "../../../images/mockup_tablet_tel.png";
import { Cloud } from "../hero/Hero";
import CourseCloud from "./CourseCloud";
import MockupAndTextUp from "./MockupAndTextUp";

const Photo = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
`;
const ColorChanger = styled.div`
  background: rgba(2, 80, 114, 0.78);
  height: 100%;
  display: grid;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: white;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }

`;

const Flex2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  flex-wrap: wrap;
  justify-content: center;
            gap: 100px;
            margin-bottom: 100px;
            align-items: normal;
         

  @media (max-width: 600px) {
    margin: 0px 20px 50px 20px;
    gap: 20px;
  }

`;

const P = styled.p`
  padding: 0px;
  margin: 0px;
`;

const Li = styled.li`
  padding: 0px 0px 0px 20px;
  margin: 0px;
`;

const Course = ({ data }) => {
  return (
    <Photo img={photo} id="how" name="how" style={{scrollMarginTop: "30px",}}>
      <ColorChanger>
        <CourseCloud title={data.title} />
        <Flex>
          <MockupAndTextUp
            mockup={data.mockupPolyglot}
            right={data.right}
            right2Title={data.right2Title}
            right2Bottom={data.right2Bottom}
          />
        </Flex>
        <Flex2>
          <div style={{ maxWidth: "420px" }}>
            <p style={{ fontWeight: "bold" }}>{data.flexDown[0].title}</p>
            <Li>{data.flexDown[0].text}</Li>
            <Li>{data.flexDown[0].text1}</Li>
            <Li>{data.flexDown[0].text2}</Li>
            {data.flexDown[0].text3? <Li>{data.flexDown[0].text3}</Li> : ""}
            <Li>{data.flexDown[0].text4}</Li>
          </div>
          <div style={{ maxWidth: "400px" }}>
            <p style={{ fontWeight: "bold" }}>{data.flexDown[1].title}</p>
            <P>{data.flexDown[1].text}</P>
          </div>
          <div style={{ maxWidth: "400px" }}>
            <p style={{ fontWeight: "bold" }}>{data.flexDown[2].title}</p>
            <P>{data.flexDown[2].text}</P>
          </div>
        </Flex2>
      </ColorChanger>
    </Photo>
  );
};

export default Course;
