import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import polyglotTitle from "../../../images/newProductPage/grafika1.webp";

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 140px 0px 40px 0px;
  gap: 40px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 360px;
`;

const Title = styled.h1`
  color: ${Colors.darkBlue};
  font-weight: bold;
  text-align: center;
  font-size: 60px;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    font-size: 45px;
    margin-bottom: 10px;
    margin-top: -50px;
  }
`;

const Subtitle = styled.div`
  color: ${Colors.darkBlue};
  font-weight: bold;
  font-size: 24px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const ImageWrapper = styled.div`
  width: 500px;
  height: 500px;
  overflow: hidden;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    width: 330px;
    height: 330px;
    margin-top: -20px;
  }
`;

const Hero = ({ data }) => {
  return (
    <Wrapper>
      <TextWrapper>
        <Title>{data.title}</Title>
        <Subtitle>{data.subtitle}</Subtitle>
      </TextWrapper>
      <ImageWrapper>
        <img
          style={{ height: "100%", width: "auto" }}
          src={data.image}
          alt="Polyglot"
        ></img>
      </ImageWrapper>
    </Wrapper>
  );
};

export default Hero;
