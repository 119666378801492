import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import api from '../../api/api';
import './ActivityPage.css';
import { useNavigate, useParams } from 'react-router';
import ExpressionTranslationActivity from '../../components/activities/ExpressionTranslationActivity/ExpressionTranslationActivity';
import ExpressionListeningActivity from '../../components/activities/ExpressionListeningActivity/ExpressionListeningActivity';
import UnscrambleExpressionLettersActivity from '../../components/activities/UnscrambleExpressionLettersActivity/UnscrambleExpressionLettersActivity';
import UnscrambleSentenceWordsActivity from '../../components/activities/UnscrambleSentenceWordsActivity/UnscrambleSentenceWordsActivity';
import ExpressionLearningActivity from '../../components/activities/ExpressionLearningActivity/ExpressionLearningActivity';
import LinkExpressionsActivity from '../../components/activities/LinkExpressionsActivity/LinkExpressionsActivity';
import getLevelColor from '../../common/functions/getLevelColor';
import Store from '../../../Store';
import getActivityName from '../../common/functions/getActivityName';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleLeft,
    faAngleRight,
    faArrowLeft,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import LessonModal from '../../components/Modals/LessonModal/LessonModal';
import StatsContext from '../../stats/context/statsContext';
import withParamColector from '../../stats/hoc/withParamCollector';
import QuestionButton from '../../components/Buttons/QuestionButton/QuestionButton';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';
import RatePoll from '../../components/Polls/RatePoll/RatePoll';
import isTruthy from '../../common/functions/isTruthy';
import TaskActivity from '../../components/activities/TaskActivity/TaskActivity';

const queryString = require('query-string');

const GAME_ACTIVITIES = {
    ExpressionListeningGame: ExpressionListeningActivity,
    ExpressionTranslationGame: ExpressionTranslationActivity,
    LinkExpressionsGame: LinkExpressionsActivity,
    UnscrambleExperssionLettersGame: UnscrambleExpressionLettersActivity,
    UnscrambleSentenceWordsGame: UnscrambleSentenceWordsActivity,
};

const Fallback = () => {
    const { resetBoundary } = useErrorBoundary();
    setTimeout(() => {
        resetBoundary();
    }, 700);

    return <></>;
};

const ActivityErrorBoundary = ({
    children,
    onError = () => {},
    onErrorLimit,
}) => {
    const [errorCounter, setErrorCounter] = useState(0);
    if (errorCounter >= 4) onErrorLimit();

    return (
        <ErrorBoundary
            FallbackComponent={Fallback}
            onError={(e) => {
                setErrorCounter((prev) => prev + 1);
                onError();
            }}
        >
            {children}
        </ErrorBoundary>
    );
};

function ActivityPage() {
    const [isValidAnswer, setIsValidAnswer] = useState(true);
    const [activityData, setActivityData] = useState({});

    const [progressComponents, setProgressComponents] = useState([]);
    const [activityComponent, setActivityComponent] = useState();
    const [isNextLessonModalOpen, setIsNextLessonModalOpen] = useState(false);
    const [isRatePollModalOpen, setIsRatePollModalOpen] = useState(false);
    const [languagePair, setLanguagePair] = useState({});

    const currentMakeAnswer = useRef(() => {});

    const params = useParams();

    const isLessonRepeated = useMemo(() => !!params.lessonId, []);

    const [currentSequenceNumber, setCurrentSequenceNumber] = useState(
        isLessonRepeated ? 0 : null
    );
    const { setLessonId } = useContext(StatsContext);
    const activityRef = useRef();
    const navigate = useNavigate();

    const isQuestionIconVisible = useMemo(() => {
        return (
            activityData?.progress?.[activityData?.currentNumber] === false ||
            !isValidAnswer
        );
    }, [activityData, isValidAnswer]);

    const backToLessonsIfNeeded = (nextSequenceNumber, lessonData) => {
        const isTask = lessonData?.type === 'task';
        if (
            (isLessonRepeated &&
            activityData?.allNumber &&
            nextSequenceNumber > activityData.allNumber) || (isTask && nextSequenceNumber > 0 && isLessonRepeated)
        ) {
            navigate(`/platform/lessons/${params.pairId}/${params.level}`);
        }
    };

    const loadActivityData = async (sequenceNumber, data) => {
        backToLessonsIfNeeded(sequenceNumber, data);
        const qs = {
            ...(isTruthy(sequenceNumber) && { sequenceNumber }),
            ...(isLessonRepeated && { lessonId: params.lessonId }),
        };
        const lessonRes = await api.get(
            `/lesson/language-pair/${params.pairId}/level/${
                params.level
            }/lesson?${queryString.stringify(qs)}`
        );

        const lessonData = lessonRes.data;
        setCurrentSequenceNumber(lessonData.currentNumber);
        setIsValidAnswer(true);
        setLessonId(lessonData.lessonId);
        setActivityData(lessonData);
        loadPorgressComponents(lessonData);
        loadActivityComponent(lessonData, lessonData.currentNumber + 1);
    };

    const maxSequenceNumber = useMemo(
        () => activityData?.allNumber,
        [activityData]
    );

    const currentLimit = useMemo(
        () => Math.min(activityData?.progress?.length, maxSequenceNumber),
        [activityData]
    );

    const loadPorgressComponents = (lessonData) => {
        const components = [];
        for (let i = 0; i <= lessonData.allNumber; i++) {
            let colorClassName = '';
            if (typeof lessonData.progress[i] === 'boolean') {
                colorClassName = lessonData.progress[i]
                    ? 'activity-page__square--completed'
                    : 'activity-page__square--failed';
            } else {
                colorClassName =
                    lessonData.currentNumber === i
                        ? 'activity-page__square--in-progress'
                        : '';
            }

            components.push(
                <div
                    key={i}
                    className={`activity-page__square 
        ${lessonData.currentNumber === i ? 'activity-page__square--active' : ''}
        ${colorClassName}        
      `}
                ></div>
            );
        }
        setProgressComponents(components);
    };

    const loadActivityComponent = (lessonData, sequenceNumber) => {
        if (lessonData.type === 'word') {
            setActivityComponent(
                <ExpressionLearningActivity
                    activityData={lessonData}
                    makeAnswer={createMakeAnswer(lessonData, sequenceNumber)}
                />
            );
        } else if (lessonData.type === 'task') {
            setActivityComponent(
                <TaskActivity
                    activityData={lessonData}
                    makeAnswer={createMakeAnswer(lessonData, sequenceNumber)}
                    isLessonRepeated={isLessonRepeated}
                />
            );
        } else {
            const GameComponent = GAME_ACTIVITIES[lessonData.gameName];
            setActivityComponent(
                <GameComponent
                    activityData={lessonData}
                    makeAnswer={createMakeAnswer(lessonData, sequenceNumber)}
                    setIsValidAnswer={setIsValidAnswer}
                />
            );
        }
    };

    const isLessonEnded = (lessonData, answer) => {
        const progressArr = [
            ...lessonData.progress,
            ...new Array(
                lessonData.allNumber + 1 - lessonData.progress.length
            ).fill(false),
        ];
        const isOnlyOneQuestionLeft =
            progressArr.filter((bool) => !bool).length === 1;
        const isCurrentQuestionBadAnswered =
            progressArr.findIndex((bool) => !bool) === lessonData.currentNumber;
        const isProperAnswer = answer;

        return (
            isProperAnswer &&
            isCurrentQuestionBadAnswered &&
            isOnlyOneQuestionLeft
        );
    };

    const createMakeAnswer = (lessonData, sequenceNumber) => {
        const newMakeAnswer = async (answer, taskAnswer) => {
            const qs = queryString.stringify({
                lessonId: lessonData.lessonId,
                sequenceNumber: lessonData.currentNumber,
            });
            await api.post(
                `/lesson/language-pair/${lessonData.pairId}/level/${lessonData.level}/lesson?${qs}`,
                { answer, taskAnswer }
            );

            setActivityComponent(null);
            if (!isLessonRepeated) {
                if (!isLessonEnded(lessonData, answer))
                    await loadActivityData();
                else {
                    if (
                        lessonData.lessonNumber === 3 &&
                        lessonData.lessonSubNumber === 1 &&
                        lessonData.level === 1
                    )
                        setIsRatePollModalOpen(true);
                    else {
                        setIsNextLessonModalOpen(true);
                    }
                }
            } else await loadActivityData(sequenceNumber, lessonData);
        };

        currentMakeAnswer.current = newMakeAnswer;
        return newMakeAnswer;
    };

    useEffect(() => {
        api.get(`/language-pair/${params.pairId}`).then(({ data }) =>
            setLanguagePair(data)
        );

        loadActivityData(currentSequenceNumber);
    }, []);

    return (
        !!Object.keys(languagePair).length &&
        !!Object.keys(activityData).length && (
            <ActivityErrorBoundary
                onError={() => currentMakeAnswer.current(true)}
                onErrorLimit={() =>
                    navigate(
                        `/platform/lessons/${params.pairId}/${params.level}`
                    )
                }
            >
                <div className="activity-page" ref={activityRef}>
                    <LessonModal
                        isModalOpen={isNextLessonModalOpen}
                        setIsModalOpen={setIsNextLessonModalOpen}
                        activityData={activityData}
                        loadNextActivity={loadActivityData}
                        languagePair={languagePair}
                    ></LessonModal>
                    <RatePoll
                        isOpen={isRatePollModalOpen}
                        setIsOpen={setIsRatePollModalOpen}
                        languagePairId={languagePair.id}
                        callback={() => {
                            setIsRatePollModalOpen(false);
                            setIsNextLessonModalOpen(true);
                        }}
                    />
                    <button
                        className="activity-page__close-button"
                        onClick={() => navigate(-1)}
                    >
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <div
                        className="activity-page__lesson-bar"
                        style={{
                            background: getLevelColor(activityData.level),
                        }}
                    >
                        <h1 className="activity-page__lesson-header">
                            {Store.getText('level')} {activityData.level} |{' '}
                            {activityData.lessonName}{' '}
                            {activityData.lessonNumber}.
                            {activityData.lessonSubNumber}
                        </h1>
                    </div>
                    <div className="activity-page__content-wrapper">
                        <div className="activity-page__activity-title-wrapper">
                            <Link
                                to={`/platform/lessons/${params.pairId}/${params.level}`}
                            >
                                <button className="activity-page__close-button activity-page__close-button--mobile">
                                    <FontAwesomeIcon icon={faXmark} />
                                </button>
                            </Link>
                            <div
                                className="activity-page__square-div"
                                style={{
                                    background: getLevelColor(
                                        activityData.level
                                    ),
                                }}
                            ></div>
                            <div className="activity-page__activity-title-div">
                                <p className="activity-page__activity-title">
                                    {getActivityName(
                                        activityData.type,
                                        activityData.gameName
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className="activity-page__activity_wrapper">
                            <button
                                onClick={() =>
                                    loadActivityData(currentSequenceNumber - 1)
                                }
                                disabled={currentSequenceNumber <= 0}
                                className="activity-page__activity-change-button activity-page__activity-change-button--left"
                            >
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </button>
                            <div></div>
                            {activityComponent}
                            <button
                                onClick={() =>
                                    loadActivityData(currentSequenceNumber + 1)
                                }
                                className="activity-page__activity-change-button activity-page__activity-change-button--right"
                                disabled={currentLimit <= currentSequenceNumber}
                            >
                                <FontAwesomeIcon icon={faAngleRight} />
                            </button>
                        </div>
                        <div className="activity-page__squares-wrapper">
                            {progressComponents}
                        </div>

                        {isQuestionIconVisible && (
                            <div className="activity-page__question-button-wrapper">
                                <QuestionButton
                                    screenRef={activityRef}
                                    lessonId={activityData.lessonId}
                                    languagePairId={activityData.pairId}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </ActivityErrorBoundary>
        )
    );
}

export default withParamColector(ActivityPage);
