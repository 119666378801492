import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";

import Lucia from "../../../images/newProductPage/lucia.png";
import Veronika from "../../../images/newProductPage/veronika.png";
import Miroslav from "../../../images/newProductPage/miroslav.png";
import Emila from "../../../images/newProductPage/emilia.png";
import Michal from "../../../images/newProductPage/michal.png";
import Lukas from "../../../images/newProductPage/lukas.png";

import Slider from "react-slick";

const Wrapper = styled.section``;

const WhitePart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0 30px;

  @media (max-width: 600px) {
  }
`;

const BluePart = styled.div`
  max-width: 100%;
  padding: 30px 30px;
  gap: 40px;
  background-image: linear-gradient(
    to top,
    ${Colors.newLightBlue} 80%,
    rgba(0, 0, 0, 0) 20%
  );

  .slick-slide > div {
    display: grid;
    place-items: center;
    margin: auto;

    @media (min-width: 600px) {
      width: 400px;
    }
  }
`;

const Title = styled.h3`
  font-size: 50px !important;
  color: ${Colors.darkBlue};
  font-weight: bold !important;
  width: 100%;
  text-align: center;

  @media (max-width: 600px) {
    margin-top: -40px;
    text-align: left;
    font-size: 40px !important;
  }
`;
const Subtitle = styled.h4`
  color: ${Colors.darkBlue};
  font-size: 22px !important;
`;

const Card = styled.div`
  border-radius: 20px;
  border: 5px solid ${Colors.darkBlue};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  height: 500px;

  @media (max-width: 600px) {
    font-size: 18px !important;
    height: 440px;
  }
`;
const CardComment = styled.h4`
  color: ${Colors.darkBlue};
  height: 350px;
  font-size: 22px !important;
  font-weight: normal !important;

  @media (max-width: 600px) {
    font-size: 18px !important;
    height: 300px;
  }
`;
const Border = styled.div`
  height: 1px;
  background-color: ${Colors.darkBlue};
`;
const CardFlexWrapper = styled.div`
  display: flex;
  justify-content: left;
  gap: 20px;
  align-items: center;
  margin-top: 15px;
`;
const CardPhoto = styled.div`
  width: 80px;
  height: 80px;
  overflow: hidden;
`;
const CardName = styled.h3`
  color: ${Colors.darkBlue};
  font-style: italic;
  font-size: 24px !important;
`;

const DATA = [
  {
    name: "Lucia",
    comment: `Či už ste začiatočník, ktorý sa práve učí základy, alebo pokročilý študent, 
      ktorý si chce rozšíriť slovnú zásobu, v Polyglot si každý nájde to svoje. Jednou z vecí, 
      ktoré sa mi na tejto platforme páčia, je rozmanitosť lekcií, od objednávania jedla v reštaurácii 
      až po objednávanie taxíka, vďaka čomu je učenie realistické a praktické. Okrem toho je veľkým
       plusom začlenenie reálnych fráz a nahrávok na správnu výslovnosť.`,
    photo: Lucia,
  },
  {
    name: "Veronika",
    comment: `Páči sa mi, že lekcie Polyglot sú prispôsobené reálnym situáciám,
     vďaka čomu môžem ľahko aplikovať to, čo som sa naučil, v skutočnom svete. 
     Po 3 mesiacoch štúdia som dostala ponuku na novú prácu!  `,
    photo: Veronika,
  },
  {
    name: "Miroslav",
    comment: `Polyglot je vynikajúcou platformou na učenie sa jazykov. 
    Vďaka rôznorodosti lekcií a cvičení je proces učenia zaujímavý a pútavý. 
    Vďaka štúdiu jazyka som sa cez internet zoznámil s 
    dievčaťom z Nemecka, s ktorým sa čoskoro ožením. Odporúčam 😊`,
    photo: Miroslav,
  },
  {
    name: "Emília",
    comment: `Podľa môjho názoru je Polyglot úžasnou platformou pre každého, 
    kto sa chce zlepšiť v jazykových zručnostiach. Platforma je navrhnutá tak, 
    aby sa učenie nového jazyka stalo príjemným zážitkom.`,
    photo: Emila,
  },
  {
    name: "Michal",
    comment: `Používam Polyglot už niekoľko mesiacov a môžem s istotou povedať, 
    že mi zmenil život. V práci som bol povýšený! Vždy som mal záujem naučiť sa 
    nový jazyk, ale nikdy som na to nemal čas ani prostriedky. S Polyglot sa 
    môžem učiť vlastným tempom a podľa vlastného harmonogramu. `,
    photo: Michal,
  },
  {
    name: "Lukas",
    comment: `Používam Polyglot už niekoľko mesiacov a musím povedať, že pre mňa zmenil pravidlá hry.
     Ako človek, ktorý sa vždy zaujímal o učenie nového jazyka, ale nikdy nemal príležitosť sa ho naučiť,
      som sa vďaka Polyglot mohol učiť veľmi jednoducho a pohodlne. Teraz dokážem porozumieť a komunikovať s 
      ľuďmi z rôznych kultúr, čo mi rozšírilo rozhľad a otvorilo nové možnosti.`,
    photo: Lukas,
  },
];

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const SuccessStory = ({ data }) => {
  const slider = useRef();
  const [settings, setSettings] = useState();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  console.log(data);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const toShow =
      window.innerWidth > 900 ? (window.innerWidth > 1200 ? 3 : 2) : 1;

    setSettings({
      infinite: true,
      speed: 500,
      slidesToShow: toShow,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      className: "slider-custom",
      // nextArrow: <SampleNextArrow slider={slider} />,
      // prevArrow: <SamplePrevArrow slider={slider} />,
    });
  }, [slider, window.innerWidth]);

  return (
    <Wrapper>
      <WhitePart>
        <Title>{data.title}</Title>
        <Subtitle>{data.description}</Subtitle>
      </WhitePart>
      <BluePart>
        <Slider {...settings} ref={slider}>
          {data.data.map((element) => (
            <Card>
              <CardComment>{element.comment}</CardComment>
              <Border />
              <CardFlexWrapper>
                <CardPhoto>
                  <img
                    src={element.photo}
                    style={{ width: "auto", height: "100%" }}
                  />
                </CardPhoto>
                <CardName>{element.name}</CardName>
              </CardFlexWrapper>
            </Card>
          ))}
        </Slider>
      </BluePart>
    </Wrapper>
  );
};

export default SuccessStory;
