import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import hero from "../../../images/zdj_2.jpg";
// import titleCloud from "../../../images/boxes/dymek_nagłówek-krótszy.png";
// import approach1 from "../../../images/approach/ikona_podejście-1.png";
// import approach2 from "../../../images/approach/ikona_podejście-2.png";
// import approach3 from "../../../images/approach/ikona_podejście-3.png";
// import approach4 from "../../../images/approach/ikona_podejście-4.png";
import {
  Background,
  Cloud,
  ColorChanger,
} from "../../reusable/exportedStyles/exportedStyles";
import Image from "./Image";
import Box from "./Box";

const Title = styled.h1`
  font-weight: lighter;
  font-size: 40px;
  max-width: 100px;
  height: auto;
  padding: 10px 0px 20px 10px;
  margin: 0px;
  padding-left: 8px;
  text-align: center;
  color: ${Colors.darkBlue};
`;

const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  display: grid;
  justify-content: center;
  margin-top: 50px;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
  }
`;

const Text = styled.p`
  max-width: 800px;
  text-align: center;
  color: ${Colors.darkBlue};
`;

const MainText = styled.p`
  max-width: 800px;
  text-align: center;
  color: ${Colors.darkBlue};
  font-weight: bold;
  font-style: italic;
`;

const LeftBox = styled.div``;

const RightBox = styled.div``;

const Flex = styled.div`
  display: flex;
  gap: 60px;
  justify-content: center;

  @media (max-width: 600px) {
    gap: 10px;
  }
`;

const Approach = ({ data }) => {
  return (
    <>
      <Background img={hero}>
        <ColorChanger>
          <div style={{ width: "150px", alignSelf: "flex-end" }}>
            {/* <Cloud img={titleCloud}> */}
            {/* <Title>{data.title}</Title> */}
            {/* </Cloud> */}
          </div>
        </ColorChanger>
      </Background>
      <Wrapper>
        <Text>{data.text1}</Text>
        <MainText>{data.text2}</MainText>
        <Text>{data.text3}</Text>
        <Flex style={{ marginTop: "50px" }}>
          {/* <Image photo={approach1} /> */}
          <Box
            title={data.paragraphData[0].title}
            text={data.paragraphData[0].text}
          />
        </Flex>
        <Flex>
          <Box
            title={data.paragraphData[1].title}
            text={data.paragraphData[1].text}
          />
          {/* <Image photo={approach2} /> */}
        </Flex>
        <Flex>
          {/* <Image photo={approach3} /> */}
          <Box
            title={data.paragraphData[2].title}
            text={data.paragraphData[2].text}
          />
        </Flex>
        <Flex style={{ marginBottom: "50px" }}>
          <Box
            title={data.paragraphData[3].title}
            text={data.paragraphData[3].text}
          />
          {/* <Image photo={approach4} /> */}
        </Flex>
      </Wrapper>
    </>
  );
};

export default Approach;
