import React from "react";
import styled from "styled-components";

import england from "../../../images/countries/mapa_anglia.png";
import french from "../../../images/countries/mapa_francja.png";
import spanish from "../../../images/countries/mapa_hiszpania.png";
import niderland from "../../../images/countries/mapa_holandia.png";
import germany from "../../../images/countries/mapa_niemcy.png";
import italy from "../../../images/countries/mapa_wlochy.png";
import russia from "../../../images/countries/mapa_rosja.png";
import portugal from "../../../images/countries/mapa_portugalia.png";
import polish from "../../../images/countries/mapa_polska.png";
import { Colors } from "../../../colors/Colors";

import plane from "../../../images/newProductPage/plane.png";

const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  gap: 20px;

  @media (max-width: 600px) {
    max-width: 350px;
  }
`;

const SectionThree = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const PhotoWraper = styled.div`
  height: 200px;
  overflow: hidden;

  @media (max-width: 600px) {
    height: 90px;
  }
`;

const Title = styled.h1`
  color: ${Colors.newYellow};
  font-size: 50px;
  margin-bottom: 20px;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 80px 0px 80px 5%;
  gap: 80px;

  @media (max-width: 600px) {
    gap: 0px;
    margin-left: 80px;
    flex-direction: column-reverse;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${Colors.newYellow};
  color: white;
  border-radius: 20px 0px 0px 20px;
  padding: 40px;
  max-height: 140px;

  @media (max-width: 600px) {
    max-height: 540px;
    padding: 20px;
  }
`;

const Text = styled.div`
  font-size: 22px;
  max-width: 900px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const PlaneWrapper = styled.div`
  height: 200px;
  overflow: hidden;

  @media (max-width: 600px) {
    height: 120px;
    margin-left: -70px;
    margin-top: -70px;
  }
`;

const PhotoSubtitle = styled.h4`
  text-align: center;
  color: ${Colors.darkBlue};
  font-size: 2.5rem;
  font-weight: lighter;

  @media (max-width: 600px) {
    font-size: 1.9rem;
  }
`;

const LANGUAGES = [
  { image: england, lang: "Angličtina" },
  { image: french, lang: "Francúzština" },
  { image: spanish, lang: "Španielčina" },
  { image: niderland, lang: "Holandčina" },
  { image: germany, lang: "Nemčina" },
  { image: italy, lang: "Taliančina" },
  { image: russia, lang: "Ruština" },
  { image: portugal, lang: "Portugalčina" },
  { image: polish, lang: "Poľština" },
];

const Languages = ({ data }) => {
  return (
    <>
      <SectionThree>
        <Title>{data.title}</Title>
        <LanguageWrapper>
          {data.languages.map(({ image, lang }) => {
            return (
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PhotoWraper>
                  <img
                    src={image}
                    alt="logo"
                    style={{ width: "auto", height: "100%" }}
                  />
                </PhotoWraper>
                <PhotoSubtitle>{lang}</PhotoSubtitle>
              </div>
            );
          })}
        </LanguageWrapper>
      </SectionThree>
      <FlexWrapper>
        <PlaneWrapper>
          <img
            src={plane}
            alt="logo"
            style={{ width: "auto", height: "100%" }}
          />
        </PlaneWrapper>
        <TextWrapper>
          <Text>{data.description}</Text>
        </TextWrapper>
      </FlexWrapper>
    </>
  );
};

export default Languages;
