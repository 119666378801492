import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import screen1 from "../../../images/newProductPage/phone/2.png";
import screen3 from "../../../images/newProductPage/phone/4.png";
import screen4 from "../../../images/newProductPage/phone/5.png";
import screen5 from "../../../images/newProductPage/phone/6.png";
import screen6 from "../../../images/newProductPage/phone/7.png";
import screen7 from "../../../images/newProductPage/phone/8.png";
import screen8 from "../../../images/newProductPage/phone/9.png";
import screen9 from "../../../images/newProductPage/phone/10.png";
import screen10 from "../../../images/newProductPage/phone/11.png";
import screen12 from "../../../images/newProductPage/phone/12.png";

import phone from "../../../images/newProductPage/phone/phone_mockup.png";
import Slider from "react-slick";

const Wrapper = styled.section`
  background-color: ${Colors.darkBlue};
  padding: 60px 0 60px 0;
  margin-top: -50px;

  @media (max-width: 600px) {
    margin-top: -100px;
  }
`;

const FirstSection = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
  margin: 80px 0 80px 0;
`;

const SecondSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;

  @media (max-width: 600px) {
    gap: 0px;
  }
`;
const ViedeoWrapper = styled.div`
  overflow: hidden;
  width: 500px;
  height: 375px;

  iframe {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 800px) {
    display: none;
    visibility: hidden;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 50px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 36px;
    text-align: left;
  }
`;

const TextWrapper = styled.h5`
  color: white;
  max-width: 420px;
  font-weight: bold;
  padding: 0px 30px;

  @media (max-width: 600px) {
    max-width: 360px;
  }
`;
const Subtitle = styled.h4`
  font-size: 22px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
const SubtitleWrapper = styled.div``;

const ParagraphTitle = styled.h3`
  font-weight: bold !important;
`;
const ParagraphSubtitle = styled.p`
  margin-bottom: 20px;
  font-weight: normal !important;
`;

const PhoneImageWrapper = styled.div`
  width: 250px;
  position: relative;
`;

const ImageWrapper = styled.div`
  position: relative;
  padding: 0px;
  top: 23px;
  left: 12px;
  width: 220px;
  border-radius: 30px !important;
  transform: translateZ(0);
  overflow: hidden;
`;

const SecondSectionTitle = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 30px;

  @media (max-width: 600px) {
    width: 300px;
    text-align: left;
    margin-top: -40px;
    margin-left: -15px;
  }
`;

const SecondTextWrapper = styled.div`
  color: white;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 600px) {
    max-width: 300px;
  }
`;

const PHOTO_DATA = [
  screen1,
  // screen2,
  screen3,
  screen4,
  screen5,
  screen6,
  screen7,
  screen8,
  screen9,
  screen10,
  screen12,
];

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
};

const SliderMobile = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    height: 500px;
    overflow: hidden;
    margin-bottom: 50px;
  }
`;

const SliderDestop = styled.div`
  width: 295px;
  height: 600px;
  display: flex;
  align-items: center;
  margin-top: -50px;
  overflow: hidden;

  @media (max-width: 800px) {
    display: none;
  }
`;

const ImageWrapperCase = styled.div`
  overflow: hidden;
  position: absolute;
  width: 240px;
`;

const ImageWrapper2 = styled.div`
  max-width: 600px;

  @media (max-width: 800px) {
    display: none;
    visibility: hidden;
  }
`;

const Learning = ({ data }) => {
  return (
    <Wrapper>
      <FirstSection>
        {data.image ? (
          <ImageWrapper2>
            <img src={data.image} style={{ width: "100%", height: "auto" }} />
          </ImageWrapper2>
        ) : (
          <ViedeoWrapper>
            <iframe
              type="text/html"
              frameborder="0"
              border="0"
              cellspacing="0"
              id="Welcome to Polyglot"
              title="Welcome to Polyglot"
              allowFullScreen
              autoPlay
              sandbox="allow-scripts allow-modal allow-popups-to-escape-sandbox allow-same-origin allow-presentation"
              src="https://www.youtube.com/embed/KkqYkR4Ik80?enablejsapi=1"
            />
          </ViedeoWrapper>
        )}
        <TextWrapper>
          <Title>{data.title}</Title>
          <SubtitleWrapper>
            <Subtitle>{data.sub1}</Subtitle>
            <Subtitle>{data.sub2}</Subtitle>
            <Subtitle>{data.sub3}</Subtitle>
          </SubtitleWrapper>
        </TextWrapper>
      </FirstSection>
      <SecondSectionTitle>
        <Title>{data.title2}</Title>
      </SecondSectionTitle>
      <SecondSection>
        <SecondTextWrapper>
          <ParagraphTitle>{data.subs[0].title}</ParagraphTitle>
          <ParagraphSubtitle>{data.subs[0].text}</ParagraphSubtitle>
          <SliderMobile>
            <PhoneImageWrapper>
              <ImageWrapperCase>
                <img
                  src={phone}
                  alt="phone"
                  style={{ width: "100%", height: "auto" }}
                />
              </ImageWrapperCase>
              <ImageWrapper>
                <div style={{ width: "217px", overflow: "hidden" }}>
                  <Slider {...settings}>
                    {data.images.map((data, i) => (
                      <img
                        src={data}
                        alt="Mobile Polyglot"
                        style={{ width: "100%", height: "auto" }}
                      />
                    ))}
                  </Slider>
                </div>
              </ImageWrapper>
            </PhoneImageWrapper>
          </SliderMobile>
          <ParagraphTitle> {data.subs[1].title}</ParagraphTitle>
          <ParagraphSubtitle>{data.subs[1].text}</ParagraphSubtitle>
          <ParagraphTitle> {data.subs[2].title}</ParagraphTitle>
          <ParagraphSubtitle>{data.subs[2].text}</ParagraphSubtitle>
          <ParagraphTitle> {data.subs[3].title}</ParagraphTitle>
          <ParagraphSubtitle>{data.subs[3].text}</ParagraphSubtitle>
        </SecondTextWrapper>
        <SliderDestop>
          <PhoneImageWrapper>
            <ImageWrapperCase>
              <img
                src={phone}
                alt="phone"
                style={{ width: "100%", height: "auto" }}
              />
            </ImageWrapperCase>
            <ImageWrapper>
              <div style={{ width: "217px", overflow: "hidden" }}>
                <Slider {...settings}>
                  {data.images.map((data, i) => (
                    <img
                      src={data}
                      alt="Mobile Polyglot"
                      style={{ width: "100%", height: "auto" }}
                    />
                  ))}
                </Slider>
              </div>
            </ImageWrapper>
          </PhoneImageWrapper>
        </SliderDestop>
      </SecondSection>
    </Wrapper>
  );
};

export default Learning;
