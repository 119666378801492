import React, { useEffect, useRef, useState } from "react";
import { StyledSection } from "./TestimonialsStyles";
import Slider from "react-slick";

import TestimonialElement from "./TestimonialElement";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../../colors/Colors";

import styled from "styled-components";
import cloudLeftDown from "../../../images/boxes/dymek_obrys_lewy_dol.png";

const H1 = styled.h1`
  font-weight: lighter;
  font-size: 30px;
  max-width: 200px;
  margin: 0px;
  padding: 10px 0 10px 20px;
  text-align: center;
  color: ${Colors.darkBlue};
`;

const Wrapper = styled.div`
  width: 200px;
  height: 100px;
  padding-top: 40px;
  margin: 30px;
`;

const SectionWrapper = styled.div`
  background-color: ${Colors.yellow};

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }
`;
const LeftSwap = styled.div`
  position: absolute;
  z-index: 99;
  display: flex;
  left: -4%;
  align-items: center;
  width: 20px;
  top: 42%;
  cursor: pointer;
  border: none;

  @media (max-width: 700px) {
    left: 1%;
  }
`;

const RightSwap = styled.div`
  position: absolute;
  top: 45%;
  right: -3%;
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  z-index: 99;

  @media (max-width: 700px) {
    right: 3%;
  }
`;

function SampleNextArrow(props) {
  const { className, style, slider } = props;
  return (
    <RightSwap
      style={{
        ...style,
      }}
      onClick={() => slider.current.slickNext()}
    >
      <FontAwesomeIcon
        icon={faAngleRight}
        style={{ color: Colors.darkBlue, fontSize: "60px" }}
      />
    </RightSwap>
  );
}

function SamplePrevArrow(props) {
  const { className, style, slider } = props;
  return (
    <LeftSwap
      style={{
        ...style,
      }}
      onClick={() => slider.current.slickPrev()}
    >
      <FontAwesomeIcon
        icon={faAngleLeft}
        style={{ color: Colors.darkBlue, fontSize: "60px" }}
      />
    </LeftSwap>
  );
}

const Cloud = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0px 30px 0px 30px;
`;

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default function Testimonials({ data }) {
  const slider = useRef();
  const [settings, setSettings] = useState();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const toShow = window.innerWidth > 800 ? 2 : 1;

    setSettings({
      infinite: true,
      speed: 500,
      slidesToShow: toShow,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow slider={slider} />,
      prevArrow: <SamplePrevArrow slider={slider} />,
    });
  }, [slider, window.innerWidth]);

  return (
    <SectionWrapper>
      <Wrapper>
        <Cloud img={cloudLeftDown}>
          <H1>{data.title}</H1>
        </Cloud>
      </Wrapper>
      <StyledSection>
        <div>
          <Slider {...settings} ref={slider}>
            {data.elements.map((data) => {
              return <TestimonialElement key={data.id} data={data} />;
            })}
          </Slider>
        </div>
      </StyledSection>
    </SectionWrapper>
  );
}
