import england from "../images/countries/mapa_anglia.png";
import poland from "../images/countries/mapa_polska.png";
import italy from "../images/countries/mapa_wlochy.png";
import germany from "../images/countries/mapa_niemcy.png";
import niderland from "../images/countries/mapa_holandia.png";
import portugal from "../images/countries/mapa_portugalia.png";
import russia from "../images/countries/mapa_rosja.png";
import spanish from "../images/countries/mapa_hiszpania.png";
import french from "../images/countries/mapa_francja.png";
import polish from "../images/countries/mapa_polska.png";

import Lucia from "../images/newProductPage/lucia.png";
import Veronika from "../images/newProductPage/veronika.png";
import Miroslav from "../images/newProductPage/miroslav.png";
import Emila from "../images/newProductPage/emilia.png";
import Michal from "../images/newProductPage/michal.png";
import Lukas from "../images/newProductPage/lukas.png";

import testimonial1 from "../images/testamonial/user.png";
import testimonial2 from "../images/testamonial/user.png";
import testimonial3 from "../images/testamonial/user.png";
import testimonial4 from "../images/testamonial/user.png";
import testimonial5 from "../images/testamonial/user.png";

import brandValuesPhoto1 from "../images/brandValues/wartosci_ambicja.webp";
import brandValuesPhoto2 from "../images/brandValues/wartosci_radosc.webp";
import brandValuesPhoto3 from "../images/brandValues/wartosci_ciekawosc.webp";
import brandValuesPhoto4 from "../images/brandValues/wartosci_efektywnosc.webp";

import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import mockup from "../images/mockup_sk.png";

import polyglotTitle from "../images/newProductPage/grafika1.webp";

import screen1 from "../images/newProductPage/phone/2.png";
import screen3 from "../images/newProductPage/phone/4.png";
import screen4 from "../images/newProductPage/phone/5.png";
import screen5 from "../images/newProductPage/phone/6.png";
import screen6 from "../images/newProductPage/phone/7.png";
import screen7 from "../images/newProductPage/phone/8.png";
import screen8 from "../images/newProductPage/phone/9.png";
import screen9 from "../images/newProductPage/phone/10.png";
import screen10 from "../images/newProductPage/phone/11.png";
import screen12 from "../images/newProductPage/phone/12.png";

export const slovak = {
  navbar: {
    data: [
      { title: "Objednať kurz", path: "/course" },
      { title: "Prihlásenie", path: "" },
      { title: "O platforme Polyglot ", path: "/product" },
      { title: "O nás", path: "/about" },
    ],
  },
  mainPage: {
    newSlovakProduct: {
      hero: {
        title: "Hovorte s istotou akýmkoľvek jazykom!",
        subtitle: `Získajte rýchlo istotu vďaka lekciám zameraným na konverzácie z
        reálneho života.`,
        image: polyglotTitle,
      },
      label: {
        number: 9,
        language: "jazykov",
        stars: "4.7",
        rest: "14k hodnotení",
        topThree: "200+ tisíc",
        bottomThree: "študenti",
      },
      video: {
        title: "Naučte sa jazyk jednoduchým spôsobom",
        sub1: "Stovky lekcií s príkladmi jazyka používaného v reálnom živote",
        sub2: "Praktické frázy vyučované v kurzoch, ktoré pripravili rodení hovoriaci",
        sub3: "Cvičenia efektívne vyučujú čítanie, počúvanie a hovorenie",
        title2: "Ako vyzerá kurz?",
        images: [
          screen1,
          // screen2,
          screen3,
          screen4,
          screen5,
          screen6,
          screen7,
          screen8,
          screen9,
          screen10,
          screen12,
        ],
        subs: [
          {
            title: "Lekcie z reálneho života",
            text: `Každá úroveň vzdelávania pozostáva z desiatok lekcií. Ak neviete, na akej úrovni sa nachádzate - začnite so základmi. Naučte sa pozdravy, dni v týždni, ako si objednať jedlo v reštaurácii. Každá lekcia obsahuje príklady z každodenného života.`,
          },
          {
            title: "Cvičenia",
            text: `Či už radi riešite krížovky denne, alebo sa naopak takýmto činnostiam radšej vyhýbate, nemožno poprieť, že tieto mentálne hádanky výborne trénujú pamäť a umožňujú vám zopakovať si naučenú látku.`,
          },
          {
            title: "Slová, frázy a výrazy",
            text: `Každú lekciu sprevádzajú starostlivo vybrané obrázky, vďaka ktorým je proces učenia intuitívny a kontextový. Najčastejšie používané témy pokrývajú širokú škálu slovnej zásoby z každodenného života a cestovania, čo umožňuje komunikovať v týchto oblastiach bez tzv. jazykového bloku.`,
          },
          {
            title: "Opýtajte sa odborníka",
            text: `Máte problém týkajúci sa jazyka alebo potrebujete pomoc s cvičením? Sme tu, aby sme vám pomohli! Funkcia Opýtajte sa odborníka vám dáva možnosť obrátiť sa na odborníka na jazyky, kedykoľvek potrebujete pomoc. Jednoducho odoslať svoju otázku a náš jazykový špecialista odpovie s riešením. Odpoveď sa objaví vo vašej doručenej pošte na našej platforme pre výučbu jazykov. Týmto spôsobom máte vždy odbornú podporu na ceste učenia sa jazykov. S funkciou Opýtajte sa odborníka nezostáva žiadna otázka bez odpovede a učenie sa stáva ľahšie než kedykoľvek predtým.`,
          },
        ],
      },
      languages: {
        title: "Dostupné jazyky",
        description:
          "Pripravte sa na ďalšiu cestu, doplňte si jazykové znalosti do životopisu alebo sa ponorte do novej kultúry: preskúmajte rozsiahlu zbierku lekcií Polyglot a naučte sa to, čo potrebujete, aby ste mohli začať hovoriť, bez ohľadu na úroveň vašich skúseností.",
        languages: [
          { image: england, lang: "Angličtina" },
          { image: french, lang: "Francúzština" },
          { image: spanish, lang: "Španielčina" },
          { image: niderland, lang: "Holandčina" },
          { image: germany, lang: "Nemčina" },
          { image: italy, lang: "Taliančina" },
          { image: russia, lang: "Ruština" },
          { image: portugal, lang: "Portugalčina" },
          { image: polish, lang: "Poľština" },
        ],
      },
      opinions: {
        title: "Úspešné príbehy",
        description: "Pozrite sa, ako sa ostatní zdokonaľujú v Polyglot",
        data: [
          {
            name: "Lucia",
            comment: `Či už ste začiatočník, ktorý sa práve učí základy, alebo pokročilý študent, 
              ktorý si chce rozšíriť slovnú zásobu, v Polyglot si každý nájde to svoje. Jednou z vecí, 
              ktoré sa mi na tejto platforme páčia, je rozmanitosť lekcií, od objednávania jedla v reštaurácii 
              až po objednávanie taxíka, vďaka čomu je učenie realistické a praktické. Okrem toho je veľkým
               plusom začlenenie reálnych fráz a nahrávok na správnu výslovnosť.`,
            photo: Lucia,
          },
          {
            name: "Veronika",
            comment: `Páči sa mi, že lekcie Polyglot sú prispôsobené reálnym situáciám,
             vďaka čomu môžem ľahko aplikovať to, čo som sa naučil, v skutočnom svete. 
             Po 3 mesiacoch štúdia som dostala ponuku na novú prácu!  `,
            photo: Veronika,
          },
          {
            name: "Miroslav",
            comment: `Polyglot je vynikajúcou platformou na učenie sa jazykov. 
            Vďaka rôznorodosti lekcií a cvičení je proces učenia zaujímavý a pútavý. 
            Vďaka štúdiu jazyka som sa cez internet zoznámil s 
            dievčaťom z Nemecka, s ktorým sa čoskoro ožením. Odporúčam 😊`,
            photo: Miroslav,
          },
          {
            name: "Emília",
            comment: `Podľa môjho názoru je Polyglot úžasnou platformou pre každého, 
            kto sa chce zlepšiť v jazykových zručnostiach. Platforma je navrhnutá tak, 
            aby sa učenie nového jazyka stalo príjemným zážitkom.`,
            photo: Emila,
          },
          {
            name: "Michal",
            comment: `Používam Polyglot už niekoľko mesiacov a môžem s istotou povedať, 
            že mi zmenil život. V práci som bol povýšený! Vždy som mal záujem naučiť sa 
            nový jazyk, ale nikdy som na to nemal čas ani prostriedky. S Polyglot sa 
            môžem učiť vlastným tempom a podľa vlastného harmonogramu. `,
            photo: Michal,
          },
          {
            name: "Lukas",
            comment: `Používam Polyglot už niekoľko mesiacov a musím povedať, že pre mňa zmenil pravidlá hry.
             Ako človek, ktorý sa vždy zaujímal o učenie nového jazyka, ale nikdy nemal príležitosť sa ho naučiť,
              som sa vďaka Polyglot mohol učiť veľmi jednoducho a pohodlne. Teraz dokážem porozumieť a komunikovať s 
              ľuďmi z rôznych kultúr, čo mi rozšírilo rozhľad a otvorilo nové možnosti.`,
            photo: Lukas,
          },
        ],
      },
    },
    hero: {
      title: "Kam vás zavedie váš nový jazyk?",
      right1: "Nové priateľstvá?",
      right2: "Dovolenka v trópoch?",
      right3: "Rodina v zahraničí?",
      middle1: "Vyššia pozícia?",
      middle2: "Lepšie známky?",
      left1: "Práca na novom mieste?",
    },
    poliglotWithNumbers: {
      title: "Polyglot v číslach",
      count: [
        {
          number: 9,
          description: "jazykov na výber",
        },
        {
          number: 24000,
          description: "používateľov",
        },
        {
          number: 238,
          description: "lekcií",
        },
      ],
    },
    poliglotMap: {
      title: "Co je Polyglot?",
      box1: "Slová, frázy a výrazy",
      boxDescription1:
        "Každá lekcia je sprevádzaná starostlivo vybranými obrázkami, vďaka ktorým je proces učenia intuitívny a kontextuálny. Najčastejšie používané témy pokrývajú širokú škálu slovnej zásoby z každodenného života a cestovania, čo umožňuje komunikovať v týchto oblastiach bez tzv. jazykového bloku.",
      box2: "Počet hodín",
      boxDescription2:
        "S pribúdajúcimi skúsenosťami sa zvyšuje počet lekcií, ku ktorým máte prístup. Ak sa rozhodnete učiť sa od začiatku, začnite s typmi lekcií, ktoré pokrývajú témy od jednoduchých po zložité. Každá lekcia nie je len o učení sa jednotlivých slovíčok, ale obsahuje aj rôzne cvičenia.",
      box3: "Iba 30 minút",
      boxDescription3:
        "Necítite sa pod tlakom. Lekcie môžete začať a ukončiť kedykoľvek. Nič sa nestane, pokiaľ vám každodenné povinnosti zaberú všetok čas. To je to, čo náš kurz odlišuje – časová flexibilita. Odporúčame však, aby ste štúdiu venovali 30 minút denne – aspoň z času na čas. Tým sa proces zapamätania ešte zefektívni a vy začnete hovoriť cudzím jazykom ešte rýchlejšie, ako rodení hovorcovia.      ",
      box4: "Certifikát",
      boxDescription4:
        "Osvedčenie o absolvovaní kurzu je veľkou cťou. Každý by predsa rád mal potvrdenie, že získal nové zručnosti. Po absolvovaní všetkých troch úrovní kurzu a zložení záverečného testu obdržíte zadarmo certifikát. Môžete si ju vytlačiť a byť na seba hrdí ako na účastníka. Gratulujeme – podarilo sa vám to. Ukážte ho rodine a priateľom, pochváľte sa šéfovi v práci a hovorte plynule cudzím jazykom!",
      box5: "3 úrovne učenia",
      boxDescription5:
        "Či už neviete cudzí jazyk vôbec, dokážete sa predstaviť, objednať si kávu alebo hovoriť so zaťom zo zahraničia - Polyglot je tu pre každého. Preto je kurz rozdelený na základnú, stredne pokročilú a pokročilú úroveň. Na každej úrovni učebné algoritmy neustále vyberajú správnu slovnú zásobu, aby učenie nikoho neprekvapilo príliš náročnou úrovňou. Po absolvovaní základnej úrovne môžete prejsť na strednú alebo pokročilú úroveň. To sú 3 kurzy za cenu jedného!      ",
    },
    avaiableLanguages: {
      title: "Dostupné jazyky",
      text: "Vždy ste sa chceli naučiť cudzí jazyk? Výber správneho jazyka je dôležité rozhodnutie, ktoré môže zmeniť váš život k lepšiemu.  ",
      text1:
        "Znalosť nového jazyka vám môže otvoriť dvere k novým, lákavým príležitostiam.",
      text2:
        "Zdokonaľovanie sa v akomkoľvek jazyku si vyžaduje čas, odhodlanie, nadšenie, motiváciu a vytrvalosť. Jazyková platforma Polyglot je navrhnutá tak, aby vám pomohla naučiť sa akýkoľvek jazyk.",
      languages: [
        { image: england, lang: "Angličtina" },
        { image: french, lang: "Francúzština" },
        { image: spanish, lang: "Španielčina" },
        { image: niderland, lang: "Holandčina" },
        { image: germany, lang: "Nemčina" },
        { image: italy, lang: "Taliančina" },
        { image: russia, lang: "Ruština" },
        { image: portugal, lang: "Portugalčina" },
      ],
      polish: { image: poland, lang: "Poľština" },
    },
    testimonial: {
      title: "Užívatelia píšu",
      elements: [
        {
          id: 1,
          photo: testimonial1,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Učí sa celá rodina!",
          text: "Polyglot je vynikajúci zdroj informácií pre študentov jazykov. Lekcie sú veľmi dobré a prehľadné.",
          bottomInfo: "Antonín Chramosta",
        },
        {
          id: 2,
          photo: testimonial2,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Čas plynie rýchlejšie s Polyglotkou!",
          text: "Polyglot sa mi páči, pretože mi predkladajú otázky až kým ich nezodpoviem správne. A správnu odpoveď vám ukážu hneď po tom, ako ju nesprávne zodpoviete. Takže sa vždy dozviem, aká by mala byť odpoveď, a potom sa môžem znova otestovať, až kým z učiva nezískam 100 %.          ",
          bottomInfo: "Jitka Malá",
        },
        {
          id: 3,
          photo: testimonial3,
          date: "24 stycznia 2022",
          stars: 4,
          title: "Učenie pre zaneprázdnených ľudí ",
          text: "Používala som Polyglot hneď po tom, ako vyšiel. Bolo to dobré, ale teraz je to oveľa lepšie ako predtým.",
          bottomInfo: "Vlasta Vrbová",
        },
        {
          id: 4,
          photo: testimonial4,
          date: "24 stycznia 2022",
          stars: 4.5,
          title: "Teraz žijem v zahraničí!",
          text: "Pred dvoma týždňami som začala používať Polyglot. Môj pokrok v poľštine je prekvapivo rýchly a učenie je veľmi zábavné. Páči sa mi interaktívny režim a práve som si uvedomila, že za rovnakú cenu sa môžem naučiť ešte viac jazykov. Je to naozaj úžasné a motivuje ma to k realizácii všetkých mojich snov o cudzích jazykoch.          ",
          bottomInfo: "Eva Paličková",
        },
        {
          id: 5,
          photo: testimonial5,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Nikdy nie je neskoro ",
          text: `
          Myslím, že tento kurz je veľmi dobrý na učenie sa angličtiny od nuly.
          Všetky kroky sú veľmi zladené a ako študent som urobil veľmi výrazný pokrok.
          `,
          bottomInfo: "Ondřej Krejsa",
        },
        {
          id: 5,
          photo: testimonial5,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Nikdy nie je neskoro ",
          text: "Aplikácia je naozaj pekná a udržiava vás motivovaných. Navyše keď som mal problém a obrátil som sa na zákaznícky servis Polyglotu, boli profesionálni, rýchli a efektívni.",
          bottomInfo: "Václav Hrabec",
        },
      ],
    },
    course: {
      title: "Aký je kurz?",
      mockupPolyglot: mockup,
      right:
        "Na platforme Polyglot nenájdete nudné, suché gramatické pravidlá, ktoré si musíte zapamätať za jeden deň. Naučíte sa vety a výrazy tak, ako sa používajú v každodennom živote, a potom si ich upevníte v pamäti pomocou jednoduchých cvičení a opakovaní. Kurz bol navrhnutý tak, aby učenie bolo príjemné.",
      right2Title: "Lekcie zo skutočného života",
      right2Bottom:
        "Každá úroveň výuky sa skladá z niekoľkých desiatok lekcií. Ak neviete, na akej ste úrovni, začnite so základmi. Naučte sa pozdravy, dni v týždni, pocity. Naučte sa, ako si objednať jedlo v reštaurácii a ako si objednať taxi. Každá lekcia obsahuje príklady z každodenného života. Každá lekcia je trochu iná, skladá sa z rôznych úloh a cvičení, ale všetky do seba zapadajú – takže si jazyk ľahko osvojíte. Lekciu môžete začať kedykoľvek",
      flexDown: [
        {
          title: "Pri učení sa stretnete s prvkami, ako sú:",
          text: `Nahrávky s výukou správnej výslovnosti,
          `,
          text1: "Frázy a vety zo života,",
          text2: "Obrázky, ktoré vám pomôžu zapamätať si slová a frázy ",
          text3: "prostredníctvom vizuálnych asociácií,",
          text4: "...a mnoho ďalších.",
        },
        {
          title: "Cvičenie",
          text: `Či už krížovky riešite radi každý deň, alebo sa im naopak radšej vyhýbate, nemožno poprieť, že tieto mentálne hádanky výborne trénujú pamäť a umožňujú opakovanie naučenej látky.`,
        },
        {
          title: "Opakovanie",
          text: `Pokročilejší účastníci si môžu opakovať slovnú zásobu a overovať svoje znalosti vo všetkých tematických oblastiach v režime priebežného učenia. Obsahuje všetku dostupnú slovnú zásobu zo všetkých kategórií. Učiaci sa algoritmus neustále kontroluje úroveň znalostí a prispôsobuje ďalší postup.`,
        },
      ],
    },
    learningWithPolyglot: {
      title: "Učenie s Polyglot",
      cards: [
        {
          icon: learning1,
          headline: `Krátke, ale systematické lekcie`,
          text: `Pri učení jazyka je najdôležitejšia pravidelnosť. Zároveň je pre nás všetkých ťažké nájsť si potrebný čas. Vzhľadom na to je program Polyglot navrhnutý tak, aby sa vyučoval v krátkych, ale systematických lekciách. `,
        },
        {
          icon: learning2,
          headline: `Lekcia bez opustenia domova`,
          text: `Na používanie výučbovej aplikácie Polyglot nepotrebujete nič iné ako telefón, počítač alebo tablet. S Polyglot sa môžete učiť kdekoľvek: vo fronte, v autobuse a predovšetkým sa môžete učiť doma bez toho, aby ste museli niekam chodiť. Učíte sa vo vani alebo pri venčení psa? S programom Polyglot je to možné!
          `,
          text2:
            "Učenie s Polyglot vám ušetrí veľa času stráveného dochádzaním a učenie môžete ľahko začleniť do svojho rozvrhu!            ",
        },
        {
          icon: learning3,
          headline: `Špičková kvalita`,
          text: `Pri vývoji Polyglot sa tím odborníkov zameral na jeden z najdôležitejších cieľov: umožniť našej metóde čo najrýchlejšie učenie cudzieho jazyka (bez ohľadu na vek študenta!).`,
        },
      ],
    },
    whyPolyglot: {
      title: "Prečo Polyglot?",
      textUp:
        "Je to platforma s mnohostranným prístupom k učeniu, ktorá vám pomôže zvládnuť nový jazyk tak, ako sa to stalo s vašim rodným jazykom – prirodzene.",
      textBottom:
        "Učenie sa cudzieho jazyka môže zmeniť život. Nielenže vám otvorí okno do sveta plného profesných i osobných príležitostí, ale taktiež rozvíja myseľ a posilňuje sebavedomie. S tým, ako sa vďaka technológiám, ako je internet, zmenšuje vzdialenosť a my sa stávame členmi globálnej komunity, sa schopnosť učiť sa jazyk stáva nevyhnutnou súčasťou našej interakcie so svetom a úspechu.",
      cards: [
        {
          headline: `Naučte sa frázy a výrazy zo skutočného života pomocou našej pokročilej technológie učenia.`,
          text: `Lekcie sú priebežne prispôsobované vašej úrovni, takže vám nikdy nehrozí, že sa začnete nudiť alebo stratíte motiváciu.
          `,
        },
        {
          headline: `Ponorte sa do cudzieho jazyka. Snažte sa plne porozumieť novému jazyku na základe znalosti kontextu.`,
          text: `Vďaka tisícom grafických a zvukových materiálov uvidíte a budete počuť ako rodení hovorcovia.
          `,
        },
        {
          headline: `Získajte istotu v rozprávaní. Skutočným cieľom učenia jazyka je tiež byť zrozumiteľný. `,
          text: `S pomocou Polyglot stačí len zopakovať slovnú zásobu, ktorú ste počuli – nebojte sa hovoriť! Náš systém vás opraví.

          `,
        },
        {
          headline: `Nedovoľte, aby vám slová unikli z pamäte. Učte sa pomocou rôznych cvičení.`,
          text: `Prvky aplikácie sme starostlivo navrhli tak, aby sa vaša myseľ nikdy nenudila. Neustále pripravujeme a pridávame do Polyglot nové výzvy, aby bolo učenie zábavné.
          `,
        },
      ],
    },
  },
  aboutUs: {
    mission: {
      title: "Misia",
      text1:
        "Naším hlavným cieľom je, aby sa pomocou technológií mohli všetci učiť rovnako kvalitne ako pri súkromnom doučovaní.",

      title2: "Univerzálny prístup",
      textTitle2:
        "Viac ako 1,2 miliardy ľudí sa učí jazyky a väčšina z nich dúfa, že vďaka tomu získa nové príležitosti. Bohužiaľ, učenie sa jazyka je nákladné a pre mnohých ľudí je prístup k nemu uzavretý.",

      title3: "Kultúrny most",
      textTitle3:
        "Veríme, že jazyk je akýmsi mostom medzi rôznymi kultúrami. Podporuje hľadanie porozumenia a konverzáciu, podporuje snahu o dosiahnutie cieľov a objavov. Pre niektorých je to kľúč k úspechu, pre iných vstupenka do nového sveta. ",

      textBottom:
        "Preto je poslaním vzdelávacej platformy Polyglot zefektívniť a spríjemniť výučbu jazykov. Využitím sily technológie môžeme prostredníctvom jazyka zjednotiť svet.",
    },
    approach: {
      title: "Prístup",
      text1:
        "Z našich rozhovorov s komunitou vyplýva, že jazyková platforma Polyglot sa páči mnohým používateľom z celého sveta.",
      text2:
        "Naším poslaním je odstraňovať komunikačné bariéry a spájať ľudí prostredníctvom jazykového vzdelávania. ",
      text3:
        "Pochopenie toho, za čím si stojíme a ako komunikujeme s našou komunitou, je nevyhnutné na to, aby sme sa prispôsobili potrebám našej rôznorodej komunity, ktorú sme si za tie roky vybudovali.",
      paragraphData: [
        {
          id: 1,
          title: "Personalizácia",
          text: "Algoritmy strojového učenia neustále pracujú na poskytovaní učebných materiálov na správnej úrovni pre každého jednotlivca.",
        },
        {
          id: 2,
          title: "Vyvážený prístup",
          text: `Polyglot Online umožňuje študentom jazyka samostatne objavovať rôzne vzory bez toho, aby sa museli sústrediť na jazykové pravidlá - presne tak, ako sme sa učili svoj prvý jazyk v detstve. Tento prístup, ktorý sa nazýva "latentné učenie", je ideálny na rozvoj pevných znalostí jazyka a jeho pravidiel na základnej úrovni.`,
        },
        {
          id: 3,
          title: "Komunita",
          text: "Jazyky pomáhajú ľuďom integrovať sa. Spoznávanie jazykov podnecuje k hľadaniu porozumenia. Komunita jazykovej platformy Polyglot spája ľudí z celého sveta.",
        },
        {
          id: 4,
          title: "Neustále zlepšovanie",
          text: "Neustále vytvárame nové a lepšie kurzy, rozširujeme našu zbierku nástrojov na učenie sa jazykov a aktualizujeme naše materiály v súlade s vývojom jazyka, aby sme aj naďalej ponúkali najlepšie jazykové vzdelávanie na svete. Podobne ako jazyk, ani my sa nikdy neprestaneme vyvíjat.",
        },
      ],
    },
    brandValues: {
      title: "Hodnoty značky",
      part2: {
        textInfo:
          "Hodnoty značky určujú, ako komunikujeme, ako pracujeme na produkte a ako budeme rozvíjať jazykovú platformu Polyglot v nasledujúcich rokoch.",
        brandValuesData: [
          {
            photo: brandValuesPhoto1,
            title: "Ambície",
            text: "Chceme, aby naši používatelia uspeli bez ohľadu na ich ciele. Sme ambiciózni a neustále posúvame hranice.",
          },
          {
            photo: brandValuesPhoto2,
            title: "Radosť",
            text: "Veríme, že učenie jazykov by malo byť zábavné. Pomáhame ľuďom získať fascinujúce výhody z ovládania cudzieho jazyka.",
          },
          {
            photo: brandValuesPhoto3,
            title: "Zvedavosť",
            text: "Cítime neustálu túžbu po vedomostiach a rozvoji. S nadšením spoznávame iné kultúry. Pomáhame ľuďom objavovať svet prostredníctvom jazykov.",
          },
          {
            photo: brandValuesPhoto4,
            title: "Účinnosť",
            text: "Chceme, aby sa ľudia učili jazyky inteligentne. Spájame vzdelávanie s technológiami.",
          },
        ],
      },
    },
  },
  formSender: {
    formControll: {
      titleUp: "Objednať",
      titleDown: "kurz",
      pageLink: "https://programy-osobniho-rozvoje.com/typ",
      title: "Máte záujem o kurz?",
      title1: "Chcete sa dozvedieť viac?",
      subtitle: "Zanechajte nám svoje číslo –",
      subtitle2: "zavoláme vám späť  ",
      button1: "Meno a priezvisko",
      button2: "Telefónne číslo",
      button3: "E-mail",
      buttonSub: "Vyžiadajte si hovor >",
      checkOne:
        "Odoslaním tohto formulára súhlasím s pravidlami akcie a vyhlasujem, že som si prečítal",
      checkOneTwo: "a",
      checkTwo: "Súhlasím aj s tým, aby ma kontaktovali na marketingové účely",
      textOne: "Informujeme vás, že",
      textTwo: `Správcom vašich osobných údajov je EUROPEAN EDUCATION AGENCY Spółka z Ograniczoną Odpowiedzialnością Spółka Komandytowa so sídlom vo Varšave, Aleja "Solidarności" 117, 00-140, zapísaná v registri podnikateľov vedenom Okresným súdom vo Varšave pod číslom KRS 0000973588, NIP 5252909818, REGON: 522141653.
      Vaše osobné údaje sa spracúvajú v súvislosti so spracovaním vášho dopytu v kontaktnom formulári alebo s plnením uzatvorenej zmluvy. Kontaktovaním nás dobrovoľne súhlasíte so spracovaním vašich osobných údajov na vyššie uvedené účely. V súvislosti so spracovaním vašich údajov máte právo na prístup, opravu, vymazanie alebo obmedzenie spracovania, právo namietať proti spracovaniu a právo na prenosnosť údajov. Máte tiež právo svoj súhlas kedykoľvek odvolať, ale odvolanie súhlasu nemá vplyv na zákonnosť spracovania vykonaného na základe súhlasu pred jeho odvolaním. Máte tiež právo podať sťažnosť dozornému orgánu. Poskytnutie údajov je samozrejme dobrovoľné, ale môže byť nevyhnutné na vybavenie žiadosti alebo v súvislosti s plnením zmluvy. Osobné údaje sa uchovávajú tak dlho, ako je to potrebné na vyššie uvedené účely, nie však počas premlčacej doby akýchkoľvek nárokov podľa všeobecného práva. Pozorne si prečítajte zásady ochrany osobných údajov.`,
      buttonMore: "Přečtěte si viac",
      buttonLess: "Přečtěte si méně",
      subOne: "pravidlá",
      subTwo: "a zásady ochrany osobných údajov.",
      buttonTXT: "Ďakujeme",
      slovakLead: "sk",
      buttonText:
        "Jeden z našich konzultantov sa vám ozve v priebehu niekoľkých nasledujúcich pracovných dní.",
      buttonText2: "Budeme v kontakte!",
      privacyLink: "/Privacy_SK.pdf",
      rulesLink: "/Regulations.pdf",
    },
  },
  footer: {
    privacy: "Ochrana údajov",
    privacyLink: "/Privacy_SK.pdf",
    rules: "Podmienky služby",
    rulesLink: "/Regulations_SK.pdf",
  },
};
