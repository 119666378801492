import api from "./platform/api/api";
import { alphabetDictionary } from "./platform/dictionary/alphabetDictionary";
import { dictionary } from "./platform/dictionary/dictionary";
import { languageDictionary } from "./platform/dictionary/languageDictionary";
import { lessonDictionary } from "./platform/dictionary/lessonDictionary";
import { versionedLessonDictionary } from "./platform/dictionary/versionedLessonDisctionary";
const CACHE_TIME = 5 * 60 * 1000;

export const POSSIBLE_LANGS = ["pl", "cs", "sk", "en", "hu", "ro"];

class Store {
  getItem(name) {
    const item = localStorage.getItem(name);
    if (item) return JSON.parse(item);
    return item;
  }

  setItem(name, value) {
    localStorage.setItem(name, JSON.stringify(value));
  }

  setItemWithTime(name, value) {
    this.setItem(name, value);
    let dates = this.getItem("setDates");
    if (!dates) dates = {};
    dates[name] = new Date();
    this.setItem("setDates", dates);
  }

  isItemExpired(name) {
    let dates = this.getItem("setDates");
    return (
      dates && dates[name] && new Date() - new Date(dates[name]) > CACHE_TIME
    );
  }

  setToken(token) {
    this.setItem("JWT", token);
  }

  getToken() {
    return this.getItem("JWT");
  }

  removeToken() {
    localStorage.removeItem("JWT");
  }

  setUserLanguage(short) {
    this.setItem("userLang", short);
  }

  setUserPlatformLanguage(short) {
    this.setItem("userPlatformLang", short ? short.substring(0,2) : short);
  }

  getUserLangShort() {
    let userLangShort = "cs";
    const langFromLs = this.getItem("userLang");

    const browserLang = navigator.language.split("-")[0];
    if (POSSIBLE_LANGS.find((short) => short === langFromLs))
      userLangShort = langFromLs;
    else if (POSSIBLE_LANGS.find((short) => short === browserLang))
      userLangShort = browserLang;
    return userLangShort;
  }

  getUserPlatformLangShort() {
    let userLangShort = "cs";
    const langFromLs = this.getItem("userPlatformLang");

    const browserLang = navigator.language.split("-")[0];
    if (POSSIBLE_LANGS.find((short) => short === langFromLs))
      userLangShort = langFromLs;
    else if (POSSIBLE_LANGS.find((short) => short === browserLang))
      userLangShort = browserLang;
    return userLangShort;
  }

  getLessonName(level, lessonNumber, version, lang) {
    if(version > 1) return this.getVersionedLessonName(level, lessonNumber, version, lang)
    const langShort = this.getUserPlatformLangShort();
    const isExists =
      lessonDictionary[langShort][`level${level}`] &&
      lessonDictionary[langShort][`level${level}`][lessonNumber];
    return isExists
      ? lessonDictionary[langShort][`level${level}`][lessonNumber]
      : `${lessonDictionary[langShort].default} ${lessonNumber}`;
  }

  getVersionedLessonName(level, lessonNumber, version, lang) {
    const userLangShort = this.getUserPlatformLangShort();
    return versionedLessonDictionary?.[version]?.[lang]?.[userLangShort]?.[level]?.[lessonNumber]
  }

  parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  getEmail() {
    return this.getToken() && this.parseJwt(this.getToken()).email;
  }

  getUsername() {
    return this.getToken() && this.parseJwt(this.getToken()).username;
  }

  isAdmin() {
    return this.getToken() && this.parseJwt(this.getToken()).role === 10;
  }

  canSeeConsultantPanel() {
    return this.getToken() && this.parseJwt(this.getToken()).role >= 5;
  }

  getText(textKey, isOutsidePlatform = false) {
    const langShort = isOutsidePlatform ? this.getUserLangShort() : this.getUserPlatformLangShort();
    return dictionary[textKey][langShort]
      ? dictionary[textKey][langShort]
      : dictionary[textKey]["pl"];
  }

  getAlphabetData(languageFromShort, languageToShort) {
    return alphabetDictionary[languageToShort][languageFromShort.replace(/[0-9]/g, '')];
  }

  getTranslation(textKey, translations) {
    const langShort = this.getUserLangShort();
    return translations[textKey][langShort];
  }

  getLanguageNameByShort(languageShort) {
    const uesrLangShort = this.getUserLangShort();
    return languageDictionary[languageShort][uesrLangShort];
  }

  async getExceptionDictionary() {
    const langShort = this.getUserLangShort();
    let dict = this.getItem("exceptionDictionary");
    if (
      !dict ||
      (!dict[langShort] && this.isItemExpired("exceptionDictionary"))
    ) {
      if (!dict) dict = {};
      dict[langShort] = (
        await api.get(`/public/exception-dictionary/${langShort}`)
      ).data;
      this.setItemWithTime("exceptionDictionary", dict);
    }
    return dict;
  }

  async getExceptionMessage(exceptionCode) {
    const dict = await this.getExceptionDictionary();
    const langShort = this.getUserLangShort();

    return dict[langShort][exceptionCode] || dict[langShort].DEF;
  }
}

export default new Store();
