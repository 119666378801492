import Store from '../../../../../../Store';
import sk from './images/sk.png'

export const mockImages = {
    sk
};

export const getMockImage = () => {
    const short = Store.getUserPlatformLangShort();
    return mockImages[short];
};
